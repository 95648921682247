import { IDocumentsItem } from "./types";

export const getDocuments = async (): Promise<IDocumentsItem[]> => {
    const response = await fetch(
        `${process.env.STRAPI_API}/legal-documents?locale=en&populate[0]=documentFile&populate[1]=localizations&populate[3]=localizations.documentFile&populate[4]=visibilityByJurisdiction&filters[visibilityByJurisdiction][${process.env.CMS_JURISDICTION}][$eq]=true&sort=sortOrder:desc`,
        {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${process.env.STRAPI_TOKEN}`,
            },
        },
    );

    const json = await response.json();

    return json.data;
};

